import request from '@/utils/assetRequest';
import {EXPORT} from '@/utils/export';
// 列表
export function pages(data) {
    return request({
        url: '/page/pages',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
// 拉取主页
export function pullPages(params) {
    return request({
        url: `/page/pullPages`,
        method: 'get',
        params,
        timeout:0
    })
}
// 获取历史开户信息
export function batchs(params) {
    return request({
        url: `/page/batchs`,
        method: 'get',
        params
    })
}
// 获取历史开户关联的广告账号信息
export function accounts(params) {
    return request({
        url: `/page/accounts`,
        method: 'get',
        params
    })
}
// 获取通知人员
export function members(params) {
    return request({
        url: `/page/members`,
        method: 'get',
        params,
        timeout:120000
    })
}
// 获取状态
export function status(params) {
    return request({
        url: `/page/status`,
        method: 'get',
        params
    })
}
// 个号角色
export function userTypes(params) {
    return request({
        url: `/page/userTypes`,
        method: 'get',
        params
    })
}
// 获取家族所有的个号
export function thirdUsers(params) {
    return request({
        url: `/page/thirdUsers`,
        method: 'get',
        params
    })
}
// 添加运营账号
export function addThirdUsers(data) {
    return request({
        url: '/page/addThirdUsers',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
// 删除运营个号
export function delThirdUsers(data) {
    return request({
        url: '/page/delThirdUsers',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
// export const exportPage = async (data) => {
//     let url = `http://192.168.1.115:8088/api/page/export`;
//     let form = document.createElement("form");
//     form.setAttribute('action', url);
//     form.setAttribute('target', "");
//     form.setAttribute('method', "post");
//     form.style.display = "none";
//     for (let item in data) {
//         let input = document.createElement("input");
//         input.setAttribute('type', "hidden");
//         input.setAttribute('name', item);
//         input.setAttribute('value', data[item]);
//         form.append(input);
//     }
//     document.body.appendChild(form);
//     form.submit();
//     document.body.removeChild(form);
//     return Promise.resolve({
//         code: 0,
//         comment: "success"
//     })
// }
// 导出主页
export function exportPage(data){
    return EXPORT('api/page/export',data)
}
// 设置通知人员
export function setting(data) {
    return request({
        url: '/page/setting',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
// 编辑主页
export function edit(params) {
    return request({
        url: '/page/edit',
        method: 'get',
        params
    })
}
// 获取我的个号
export function myThirdUsers(params) {
    return request({
        url: '/page/myThirdUsers',
        method: 'get',
        params
    })
}
// 获取小组
export function groups(params) {
    return request({
        url: '/page/groups',
        method: 'get',
        params
    })
}
// 设置最后一次更新时间
export function setLastUpdateDate(data) {
    return request({
        url: '/page/setLastUpdateDate',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}