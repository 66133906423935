<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    :title="title"
    append-to-body
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formModel"
      :rules="formRules"
      label-width="120px"
      ref="formRef"
    >
      <el-form-item label="主页链接" prop="mainUrl">
        <el-select
          filterable
          clearable
          placeholder="主页链接"
          v-model="formModel.mainUrl"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in mainUrls"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优化师" prop="optimizer">
        <el-select
          filterable
          clearable
          placeholder="优化师"
          v-model="formModel.optimizer"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in optimizers"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户批次号" prop="batchNum">
        <el-select
          filterable
          clearable
          placeholder="开户批次号"
          v-model="formModel.batchNum"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in batchNums"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button
      ><el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "addEditMainPage",
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "标题",
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mainUrls:[],
      optimizers:[],
      batchNums:[],
      formModel: {
        mainUrl: "",
        optimizer: "",
        batchNum: "",
      },
      formRules: {
      },
    };
  },
  methods: {
    handleClose() {
      
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          console.log("this.formModel:", this.formModel);
        }
      });
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
  watch: {
    initData: {
      handler(val) {
        console.log("watch-initData", val);
        Object.keys(this.formModel).forEach((item) => {
          this.formModel[item] = val[item];
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>