<template>
  <div class="shareAudience">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm"
    >
      <el-form-item label="地区" prop="country">
        <el-select
          v-model="ruleForm.country"
          clearable
          filterable
          placeholder="选择受众所在地区"
          multiple
          value-key="key"
        >
          <el-option
            v-for="(item, index) in data_obj.TargetingCountry"
            :key="index"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品类" prop="category">
        <el-select
          v-model="ruleForm.category"
          placeholder="请选择"
          filterable
          multiple
          value-key="id"
        >
          <el-option
            v-for="item in cateList"
            :key="item.id"
            :label="item.full_name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="共享给组织部门" prop="sharedOrg">
        <el-select
          v-model="ruleForm.sharedOrg"
          placeholder="请选择组织部门"
          filterable
          value-key="code"
        >
          <el-option :label="item.name" :value="item" v-for="item in orgList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="共享给团队" prop="sharedAreas">
        <el-select v-model="ruleForm.sharedAreas" placeholder="请选择团队" multiple filterable value-key="id">
          <el-option :label="item.name" :value="item" v-for="item in teamList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="共享给优化师" prop="sharedUsers">
        <el-select
          v-model="ruleForm.sharedUsers"
          placeholder="请选择优化师"
          multiple
          filterable
          value-key="id"
        >
          <el-option :label="item.name" :value="item" v-for="item in optimizeList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述" prop="desc">
        <el-input
          v-model="ruleForm.desc"
          type="textarea"
          placeholder="请添加描述"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancle">取 消</el-button>
      <el-button type="primary" size="small" @click="creat">{{edit?'保 存':'创 建'}}</el-button>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/customAudience.js";
let cateList = require("../categoryAudience/cate.json");
export default {
  props: {
    data_obj: {
      type: Object,
      default: () => {},
    },
    audience_id:{
      type:String,
      default:''
    },
    rowMsg:{
      type:Object,
      default:()=>{}
    }
  },
  watch:{
    rowMsg:{
      handler(){
        if(this.rowMsg && Object.keys(this.rowMsg).length){
          this.edit = true;
          this.ruleForm = {sharedUsers:this.rowMsg.right_users,sharedAreas:this.rowMsg.right_areas,sharedOrg:this.rowMsg.shared_org,country:this.rowMsg.country,category:this.rowMsg.category,desc:this.rowMsg.description}
          this.ruleForm.country = this.ruleForm.country.length?this.ruleForm.country.map(v=>{
            v.key = v.id;
            return v;
          }):[];
        }else{
          this.edit = false
          this.ruleForm = {}
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {},
      cateList: cateList.list.filter((v) => v.level == 1 || v.level == 2),
      orgList: [],
      teamList:[],
      optimizeList:[],
      edit:false,
      rules:{
        country:[{required:true,message:'请选择地区',trigger:'blur'}],
        category:[{required:true,message:'请选择',trigger:'blur'}],
        sharedOrg:[{required:true,message:'请选择组织部门',trigger:'blur'}],
        sharedAreas:[{required:true,message:'请选择团队',trigger:'blur'}],
        sharedUsers:[{required:true,message:'请选择优化师',trigger:'blur'}],
        desc:[{required:true,message:'请添加描述',trigger:'blur'}],
      }
    };
  },
  methods: {
    cancle() {
      this.$emit("clear", "shareAudienceShow");
    },
    creat() {
      this.$refs.ruleForm.validate(v=>{
        if(v){
          this.ruleForm.country = this.ruleForm.country.map(v=>{
            v.id = v.key;
            return v
          })
          if(this.edit){
            this.$showLoading()
            API.updateShare({...this.ruleForm,audienceId:this.audience_id}).then(res=>{
              this.$hideLoading()
              if(res.code == 0){
                this.$message({
                  type:'success',
                  message:'更新成功'
                })
                this.$emit('getList');
                this.$emit("clear")
              }
            })
          }else{
            this.$showLoading()
            API.createShare({...this.ruleForm,audienceId:this.audience_id}).then(res=>{
              this.$hideLoading()
              if(res.code == 0){
                this.$message({
                  type:'success',
                  message:'添加成功'
                })
                this.$emit("clear")
              }
            })
          }
        }
      })
      
    },
    getGimpMsg(type,fileds,dataList){
      let params = {
        type:type,
        fields:["id" , "name"].concat(fileds),
        page_size:10000,
      }
      API.gimpQuery(params).then(res=>{
        this[dataList] = res.data.list.map(v=>{
          v.id = v.id + '';
          return v
        })
      })
    },
  },
  mounted(){
    this.getGimpMsg('gk_user',[],'optimizeList');
    this.getGimpMsg('gk_sys_area',[],'teamList');
    this.getGimpMsg('gk_org',['code'],'orgList');
    
  }
};
</script>

<style lang="scss">
.shareAudience {
  .demo-ruleForm{
    width: 600px;
  }
  .dialog-footer {
    text-align: right;
  }
  .el-select {
    width: 600px;
  }
}
</style>