import request from '@/utils/assetRequest'
import { BASEURL } from '@/utils/assetRequest'
// 列表
export function pages(data) {
    return request({
        url: '/batch/pages',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
// 获取平台
export function platforms(params) {
    return request({
        url: `/batch/platforms`,
        method: 'get',
        params
    })
}
// 获取个号
export function thirdLoginUsers (params) {
    return request({
        url: `/batch/thirdLoginUsers`,
        method: 'get',
        params
    })
}
// 获取bm
export function userBms(params) {
    return request({
        url: `/batch/userBms`,
        method: 'get',
        params
    })
}
// 获取代理商
export function agents(params) {
    return request({
        url: `/batch/agents`,
        method: 'get',
        params
    })
}
// 获取主体
export function mySubjects(params) {
    return request({
        url: `/batch/mySubjects`,
        method: 'get',
        params
    })
}
// 获取主页
export function fbPages(params) {
    return request({
        url: `/batch/fbPages`,
        method: 'get',
        params
    })
}
// 获取优化组长
export function optLeaders(params) {
    return request({
        url: `/batch/optLeaders`,
        method: 'get',
        params
    })
}
// 添加开户信息
export function add(data) {
    return request({
        url: '/batch/add',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
// 获取开户信息
export function detail(params) {
    return request({
        url: '/batch/detail',
        method: 'get',
        params
    })
}
// 删除开户信息
export function deleteBatch(params) {
    return request({
        url: '/batch/delete',
        method: 'get',
        params
    })
}
// 下载导入模板
export let template = `${BASEURL}/batch/importTemplate?_token=${JSON.parse(localStorage.getItem('userInfo')).token}`
// 主题信息导入
export function importBatchs(data) {
    return request({
        url: '/batch/importBatchs',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        transformRequest: [function (data) {
            return data
        }]
    })
}