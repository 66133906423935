<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    title="查看详情"
    width="1210px"
    @close="closeDialog"
    @opened="getDetail"
    :close-on-click-modal="false"
  >
    <div class="content">
      <!-- 申请信息 -->
      <p class="title">申请信息</p>
      <el-row :gutter="20">
        <el-col v-for="(item, index) in applyInfo" :key="index" :span="8">
          <span class="text">{{ item.text }}</span>
          <span v-if="item.prop == 'fbBm'">{{ detailMsg.fbBusiness?detailMsg.fbBusiness.name : "--" }}</span>
          <span v-else-if="item.prop == 'agent'">{{ detailMsg.agent?detailMsg.agent.name :"--" }}</span>
          <span v-else-if="item.prop == 'thirdLoginUser'">{{ detailMsg.fbUser?detailMsg.fbUser.name :"--" }}</span>
          <span v-else>{{ detailMsg[item.prop] || "--" }}</span>
        </el-col>
      </el-row>
      <!-- 开户主体 -->
      <p class="title">开户主体</p>
      <el-row :gutter="20">
        <el-col>
          <span class="text">公司名称</span>
          <span>{{ detailMsg.subject?detailMsg.subject.name : "--" }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col>
          <span class="text">开户网址</span>
          <span>{{ detailMsg.webSites?detailMsg.webSites.map(v=>v.full_url).join('，') :"--" }}</span>
        </el-col>
      </el-row>
    </div>
    <!-- 主页信息 -->
    <main-page-info
      :initData="detailMsg"
      :detail="true"
      :statusList="statusList"
    ></main-page-info>
    <!-- 广告账户信息 -->
    <ad-account-info
      :tableData="accountTableData"
      :colData="accountColData"
    ></ad-account-info>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import mainPageInfo from "../mainPageInfo.vue";
import adAccountInfo from "../adAccountInfo.vue";
import {detail} from '@/api/openAccount'
import { status } from "@/api/mainPage";
export default {
  name: "viewDetail",
  components: {
    mainPageInfo,
    adAccountInfo,
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    openStatues: {
      type: Array,
      default: () => [],
    },
    detailsInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      applyInfo: [
        { text: "平台", prop: "platform" },
        { text: "开户批次号", prop: "batch_num" },
        { text: "所属BM", prop: "fbBm" },
        { text: "申请时间", prop: "apply_time" },
        { text: "开户优化组长", prop: "opt_leader_name" },
        { text: "开户优化师", prop: "creator_name" },
        { text: "代理", prop: "agent" },
        { text: "二级代理", prop: "secondaryAgent" },
        { text: "开户数量", prop: "account_count" },
        { text: "开户个号", prop: "thirdLoginUser" },
        { text: "状态", prop: "status" },
        { text: "开户完成时间", prop: "completed_time" },
        { text: "失败原因", prop: "fail_reason" },
      ],
      openAccountEntity: [
        { text: "公司名称", prop: "name" },
        { text: "开户网站", prop: "website" },
      ],
      tableData: [
        {
          batchNum: "开户批次号1",
        },
        { batchNum: "开户批次号2" },
      ],
      colData: [
        {
          label: "开户批次号",
          prop: "batchNum",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "主页链接",
          prop: "mainUrl",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "主页优化师",
          prop: "optimizer",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "使用情况",
          prop: "applyInfo",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "最近更新日期",
          prop: "updateTime",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "主页评分",
          prop: "mainPageGrade",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "主页资料填写状态",
          prop: "mainInfoStatus",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "优化师个号",
          prop: "num",
          align: "center",
          headerAlign: "center",
        },
      ],
      accountTableData: [],
      accountColData: [
        {
          label: "广告账户ID",
          prop: "id",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "账户名称",
          prop: "name",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "优化师",
          prop: "owner_name",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户批次号",
          prop: "batch_um",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "代理商",
          prop: "agent_name",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "注册日期",
          prop: "create_time",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "账户状态",
          prop: "status",
          align: "center",
          headerAlign: "center",
        },
      ],
      detailMsg:{},
      statusList:[]
    };
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    getStatus() {
      status().then((res) => {
        this.statusList = res.data;
      });
    },
    getDetail(){
      this.getStatus();
      this.$showLoading();
      detail({id:this.detailsInfo.id}).then(res=>{
        console.log(res);
        this.$hideLoading();
        if(res.code == 0){
          this.detailMsg = res.data;
          console.log(this.openStatues);
          console.log(this.openStatues.find(v=>v.id == res.data.status));
          this.detailMsg.status = this.openStatues.find(v=>v.id == res.data.status).name
          this.accountTableData = res.data.accounts;
          this.accountTableData.map(v=>{
            v.owner_name = v.ext?.ownerName||'--';
            v.batct_num = res.data.batch_num;
            v.agent_name = res.data.agent.name;
            v.status = v.ext?.status || '--';
          })
        }
      })
      
    }
    
  },
};
</script>

<style lang="scss" scoped>
.content {
  & > .title {
    line-height: 40px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 10px;
  }
  .el-col {
    line-height: 40px;
    margin-bottom: 22px;
    display: flex;
    color: #666;
    .text {
      width: 120px;
      align-self: flex-start;
    }
  }
}
</style>