export const EXPORT = async (link, data) => {
    let url = `${link}`;
    let form = document.createElement("form");
    form.setAttribute('action', url);
    form.setAttribute('target', "_blank");
    form.setAttribute('method', "post");
    form.style.display = "none";
    for (let item in data) {
        let input = document.createElement("input");
        input.setAttribute('type', "hidden");
        input.setAttribute('name', item);
        input.setAttribute('value', data[item]);
        form.append(input);
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
    return Promise.resolve({
        code: 0,
        comment: "success"
    })
};