<template>
  <div>
    <div class="header">
      <span class="title">主页信息{{ tableData.length }}</span>
      <el-button
        v-if="!detail"
        type="text"
        icon="el-icon-folder-add"
        @click="addMainPage"
        >添加主页</el-button
      >
    </div>
    <div class="table-area">
      <el-table :data="tableData" :border="true" ref="table">
        <el-table-column
          prop="batch_num"
          label="开户批次号"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="main_page"
          label="主页"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              <el-select
                filterable
                clearable
                placeholder="选择主页"
                @focus="getPages"
                v-model="scope.row.page"
                @change="setMsg(scope.row.page, scope.$index)"
                value-key="id"
                :disabled="detail"
                :loading="loading"
              >
                <el-option
                  :label="item.name"
                  :value="item"
                  v-for="(item, index) in pageList"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="使用情况" width="160" align="center">
          <template slot-scope="scope">
            <div>
              <span>{{
                scope.row.ext && scope.row.ext.marked_status
                  ? statusList.find((v) => v.key == scope.row.ext.marked_status) .value
                  : "--"
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lastModifyTime"
          label="最近更新日期"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="overallStarRating"
          label="主页评分"
          align="center"
        >
        </el-table-column>
        <el-table-column label="主页资料填写状态" width="160" align="center">
          <template slot-scope="scope">
            <div>
              <span>{{
                scope.row.ext.fill_status == "NOT_FILLED" ? "未填写" : "已填写"
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="batch_num"
          label="操作"
          align="center"
          v-if="!detail"
        >
          <template slot-scope="scope">
            <div>
              <el-link @click="deleteMainPage(scope.$index)" type="primary"
                ><i class="el-icon-delete"></i>删除</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加、编辑主页 -->
    <add-edit-main-page
      :visible="addEditMainPageVisible"
      :title="dialogTitle"
      :initData="editInitData"
      @close-dialog="addEditMainPageVisible = false"
    ></add-edit-main-page>
  </div>
</template>

<script>
import cusTable from "@/views/components/cusTable.vue";
import addEditMainPage from "./dialog/addEditMainPage";
import { fbPages } from "@/api/openAccount";
export default {
  name: "mainPageInfo",
  components: { cusTable, addEditMainPage },
  props: {
    batch_num: {
      type: String,
      default: "",
    },
    user_num: {
      type: String,
      default: "",
    },
    initData: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Boolean,
      default: () => true,
    },
    statusList:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
      total: 0,
      tableParams: {
        border: true,
        // height: "300px",
      },
      addEditMainPageVisible: false,
      dialogTitle: "",
      editInitData: {},
      tableData: [],
      pageList: [],
      loading:false,
    };
  },
  methods: {
    addMainPage() {
      if (!this.batch_num) {
        this.$message({
          type: "warning",
          message: "请先填写开户批次号",
        });
        return false;
      }
      if (!this.user_num) {
        this.$message({
          type: "warning",
          message: "请先选择开户个号",
        });
        return false;
      }
      this.tableData.push({ batch_num: this.batch_num, ext: {} });
      // this.dialogTitle = "添加主页";
      // this.editInitData = {};
      // this.addEditMainPageVisible = true;
    },
    getPages() {
      this.loading = true;
      this.pageList = [];
      fbPages({ thirUserId: this.user_num || this.initData.fbUser.id }).then(
        (res) => {
          this.loading = false;
          this.pageList = res.data;
        }
      );
    },
    
    setMsg(v, index) {
      if (!v) {
        this.$set(this.tableData, index, { batch_num: this.batch_num });
        return false;
      }
      this.tableData[index] = { ...this.tableData[index], ...v };
      this.$set(this.tableData, index, { ...this.tableData[index], ...v });
      this.$refs.table.doLayout();
    },
    deleteMainPage(index) {
      this.tableData.splice(index, 1);
    },
    editMainPage(row) {
      this.dialogTitle = "编辑主页";
      this.editInitData = { ...row };
      this.addEditMainPageVisible = true;
    },
  },
  watch: {
    initData: {
      handler(val) {
        if (val) {
          // this.tableData = [];
          this.getPages();
          this.tableData = JSON.parse(JSON.stringify(this.initData.pages));
          this.tableData.map((v) => {
            console.log(v);
            v.batch_num = this.initData.batch_num;
            v.page = v;
          });
        }
      },
      deep:true
    },
    user_num:{
      handler(){
        this.tableData = []
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  .title {
    color: #409eff;
    border-bottom: 1px solid #409eff;
    cursor: pointer;
    padding: 10px 0;
    margin-bottom: 10px;
  }
}
</style>
