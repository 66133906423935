<template>
  <div>
    <div class="header">
      <span class="title">广告账户信息{{ tableData.length }}</span>
    </div>
    <div class="table-area">
      <cus-table
        ref="cusTableRef"
        :tableParams="tableParams"
        :tableData="tableData"
        :colData="colData"
      >
        <!-- <template v-slot:colslot="{ scope, prop }">
          <div v-if="prop == 'status'">
            {{ statusList.find(v=>v.id == scope.row.status).name}}
          </div>
        </template> -->
      </cus-table>
    </div>
  </div>
</template>

<script>
import cusTable from "@/views/components/cusTable.vue";
export default {
  name: "adAccountInfo",
  components: { cusTable },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    colData: {
      type: Array,
      default: () => [],
    },
    hasAddMainPage: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      total: 0,
      statusList:[{id:'IN_USE',name:'在用'},{id:'PLAN_USE',name:'备用'},{id:'LIMITATION',name:'受限'},{id:'LOCKED',name:'被封'},{id:'NOT_PUBLISHED',name:'主动未发布'}],
      tableParams: {
        border: true,
        // height: "300px",
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  .title {
    color: #409eff;
    border-bottom: 1px solid #409eff;
    cursor: pointer;
    padding: 10px 0;
    margin-bottom: 10px;
  }
}
</style>