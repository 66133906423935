import request from '@/utils/assetRequest';
export function pages(data) {
    return request({
        url: '/bm/pages',
        method: 'post',
        data: data,
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
// 查询所有主体
export function queryAll(params) {
    return request({
        url: `/subject/queryAll`,
        method: 'get',
        params
    })
}